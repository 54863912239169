@tailwind base;
@tailwind components;
@tailwind utilities;

/*@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Black.ttf') format('truetype');
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */


.wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.1s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }

  .parent-bg-1{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), url('src/assets/images/charles.png');
    background-position: 50% 50%;
    
    background-size: 400px 400px;
  }



  .parent-bg-2{
    background:linear-gradient(0deg,  rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), url('src/assets/images/alexandre.png');

    background-position: 50% 50%;
    
    background-size: 400px 400px;
  }


